import $ from 'jquery';
import './lib/jquery-plugin-query-object';

import Foundation from './lib/foundation-explicit-pieces';
import Popper from '../../../node_modules/popper.js/dist/popper';
import ShowButton from './lib/ShowButton';
import './calendar';
import '../../../node_modules/slick-carousel/slick/slick';

// ACCESSIBILITY | FONT SIZE (part 1)
if (localStorage.getItem('fontSize')) {
  document.querySelector('html').classList = localStorage.getItem('fontSize');
}

$(document).foundation();

$(() => {

  //////////////////////////////////////////////////////////////////////////////
  // ACCESSIBILITY | FONT SIZE (part 2)
  //////////////////////////////////////////////////////////////////////////////

  $('.footer-sizes__item').on('click', function () {

    let size = $(this).data('size');

    localStorage.setItem('fontSize', size);
    document.querySelector('html').classList = localStorage.getItem('fontSize');

    window.scrollTo(0, document.body.scrollHeight);

  });

  //////////////////////////////////////////////////////////////////////////////
  // NAVIGATION
  //////////////////////////////////////////////////////////////////////////////

  $('.active-item').closest('.header-submenu__navigation-item').addClass('header-submenu__navigation-item--active');
  $('.active-item').closest('.header-bottom__first-nav-item').addClass('header-bottom__first-nav-item--active');
  $('.active-item').closest('.header-bottom__second-nav-item').addClass('header-bottom__second-nav-item--active');

  $('.hamburger').on('click', function () {

    if ($(this).hasClass('is-active')) {
      $('.header-submenu').fadeOut();
    }

    $('.header-bottom').toggleClass('header-bottom--show');
    $('body').toggleClass('lock');
    $(this).toggleClass('is-active');

  });

  $('.header-mobile-menu__nav-item').on('click', function (e) {

    let target = $(this).attr('href');

    if (target.includes('#')) {
      e.preventDefault();
      $(target).slideDown();
      $('body').addClass('lock');
    }

  });

  $('.header-submenu__return-btn').on('click', function () {

    $('.header-submenu').slideUp();
    $('body').removeClass('lock');

  });

  $('.header-bottom__first-nav-link, .header-bottom__second-nav-link').on('click', function (e) {

    e.preventDefault();

    if ($(this).hasClass('active')) {
      $('.header-bottom__first-nav-link, .header-bottom__second-nav-link').removeClass('active');
    } else {
      $('.header-bottom__first-nav-link, .header-bottom__second-nav-link').removeClass('active');
      $(this).addClass('active');
    }

    let target = $(this).attr('href');

    if ($(target).is(':visible')) {

      $('.header').removeClass('header--active');
      $(target).slideUp();
      $(target).removeClass('header-submenu--active');

    } else if ($('.header-submenu').is(':visible')) {

      if (Foundation.MediaQuery.atLeast('large')) {
        $('.header-submenu').fadeOut();
        $(target).fadeIn();
      } else {
        $('.header-submenu').slideUp();
        $(target).slideDown();
      }

    } else {

      $('.header').addClass('header--active');
      $('.header-submenu').slideUp();
      $(target).slideDown();
      $(target).addClass('header-submenu--active');

    }

  });

  // COOKIES  //////////////////////////////////////////////////////////////////

  document.getElementById('cookie-bar__consent-btn').addEventListener('click', (e) => {
    e.preventDefault();
    const d = new Date();
    d.setTime(d.getTime() + (365*24*60*60*1000));
    document.cookie =`cookiesaccepted=1; expires=${d.toUTCString()}`;
    document.getElementById('cookie-bar').classList.add('hidden');
  });

  // SEARCH MODAL //////////////////////////////////////////////////////////////

  $('.header-bottom__search-btn').on('click', () => {
    $('.search-modal').fadeIn();
    $('.search-modal input').trigger('focus');
  });

  $('.search-modal__close-btn').on('click', () => {
    $('.search-modal').fadeOut();
    $('.header-bottom__search-btn').trigger('focus');
  });

  // STICKY MENU ///////////////////////////////////////////////////////////////

  let view = 0;

  $(window).on('scroll', function () {

    view = document.documentElement.scrollTop || document.body.scrollTop;

    if (view > 0) {
      $('.header').addClass('header--sticky');
    } else {
      $('.header').removeClass('header--sticky');
    }

  });

  //////////////////////////////////////////////////////////////////////////////
  // HOME PAGE
  //////////////////////////////////////////////////////////////////////////////

  const $homePageSlick = $('.home-slider');

  $homePageSlick.slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000
  });

  // HOME PAGE EVENTS
  const $homePageEventsSlick = $('.home-events-slick');

  $homePageEventsSlick.slick({
    slidesToShow: 1,
    prevArrow: '<button class="slick-prev"><span>&#9664;</span></button>',
    nextArrow: '<button class="slick-next"><span>&#9654;</span></button>',
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });

  $('.home-events__icon--calendar').on('click', function () {
    $(this).closest('.home-events-slick__item').find('.events-list__item-add-box').stop().fadeToggle();
  });

  if ($('.home-news__item').length > 0) {

    // HOME PAGE NEWS
    $('.home-news__item').each(function (index) {

      if (index >= 4) {
        $(this).hide();
      }

      if (Foundation.MediaQuery.atLeast('large') && index >= 3) {
        $(this).hide();
        $('.home-news__load-more-btn').data('iteration', 1).show();
      }

    });

    let homeNewsIteration = 1;

    $('.home-news__load-more-btn').on('click', function (e) {

      e.preventDefault();

      $('.home-news__item').each(function (index) {

        if (homeNewsIteration == 1 && index > 2 && index < 6) {
          $(this).fadeIn();
        }

        if (homeNewsIteration == 2 && index >= 6) {
          $(this).fadeIn();

          $('.home-news__load-more-btn span').text('Zobacz wszystkie');

        }

        if (homeNewsIteration == 3) {

          window.location = $('.home-news__load-more-btn').attr('href');

        }

      });

      homeNewsIteration++;

    });
  }

  //////////////////////////////////////////////////////////////////////////////
  // EMPLOYEE
  //////////////////////////////////////////////////////////////////////////////

  new Foundation.Accordion($('.accordion'), {
    multiExpand: true,
    allowAllClosed: true
  });

  new Foundation.Dropdown($('#employee-navigation-dropdown'), {
    position: 'bottom',
    alignment: 'left',
    closeOnClick: true
  });

  //////////////////////////////////////////////////////////////////////////////
  // ARTICLES
  //////////////////////////////////////////////////////////////////////////////

  new Foundation.Dropdown($('#articles-years-dropdown'), {
    position: 'bottom',
    alignment: 'left',
    closeOnClick: true
  });

  new Foundation.Dropdown($('#articles-months-dropdown'), {
    position: 'bottom',
    alignment: 'left',
    closeOnClick: true
  });

  new Foundation.Dropdown($('#list-years-dropdown'), {
    position: 'bottom',
    alignment: 'left',
    closeOnClick: true
  });

  new Foundation.Dropdown($('#list-types-dropdown'), {
    position: 'bottom',
    alignment: 'left',
    closeOnClick: true
  });

  new Foundation.Dropdown($('#list-statuses-dropdown'), {
    position: 'bottom',
    alignment: 'left',
    closeOnClick: true
  });

  //////////////////////////////////////////////////////////////////////////////
  // SIBEBAR NAVIGATION
  //////////////////////////////////////////////////////////////////////////////

  $('.nav-sidebar a').on('click', function (e) {

    e.preventDefault();

    let subMenu = $(this).closest('li').children('ul'),
      target = $(this).attr('href');

    if (subMenu.length > 0) {
      subMenu.slideToggle();
    } else {
      window.location = target;
    }

  });

  if ($('.nav-sidebar').length > 0) {

    let $element = $('.articles-list .nav-sidebar .active-item');

    do {

      $element.parent().closest('ul').addClass('active-menu');
      $element = $element.parent().closest('li');
      $element.addClass('active-item');

      if ($element.parent().closest('li').length == 0) {
        $element.addClass('open');
      }

    } while ($element.parent().closest('li').length !== 0);

  }

  //////////////////////////////////////////////////////////////////////////////
  // CONFERENCES
  //////////////////////////////////////////////////////////////////////////////

  const featuredConferenceSlick = $('.featured-conferences__slick');

  featuredConferenceSlick.slick({
    prevArrow: '<button class="slick-prev"><span>&#9664;</span></button>',
    nextArrow: '<button class="slick-next"><span>&#9654;</span></button>'
  });

  const conferenceSponsorsSlick = $('.conference-sponsors__slick');

  conferenceSponsorsSlick.slick({
    slidesToShow: 2,
    infinite: true,
    mobileFirst: true,
    prevArrow: '<button class="slick-prev"><span>&#9664;</span></button>',
    nextArrow: '<button class="slick-next"><span>&#9654;</span></button>',
    responsive: [
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4
        }
      }
    ]
  });

  //////////////////////////////////////////////////////////////////////////////
  // PERSON (STAKEHOLDERS & ABSOLVENTS)
  //////////////////////////////////////////////////////////////////////////////

  const personSlick = $('.person__slick');

  personSlick.slick({
    fade: true,
    draggable: false,
    adaptiveHeight: true,
    prevArrow: '<button class="slick-prev"><span>&#9664;</span></button>',
    nextArrow: '<button class="slick-next"><span>&#9654;</span></button>'
  });

  // NAVIGATE TO CHOSEN PERSON IN SLICK SLIDERS
  $('.person__list-item').on('click', function () {

    let number = $(this).data('slick-item'),
      target = $('#person-' + number).closest('.slick-slide').data('slick-index');

    personSlick.slick('slickGoTo', target);

  });

  //////////////////////////////////////////////////////////////////////////////
  // SCIENCE CLUB
  //////////////////////////////////////////////////////////////////////////////

  $('.science-club__header').on('click', function () {

    let $that = $(this);

    $that.closest('.science-club').toggleClass('science-club--active')
      .find('.science-club__content').stop().slideToggle();

  });

  //////////////////////////////////////////////////////////////////////////////
  // ABOUT US
  //////////////////////////////////////////////////////////////////////////////

  $('.about-history__timeline-item').on('click', function () {

    if (!$(this).hasClass('about-history__timeline-item--active')) {
      $('.about-history__timeline-item').removeClass('about-history__timeline-item--active');
      $(this).addClass('about-history__timeline-item--active');

      $('.about-history__content-item').hide();

      let $tab = $('.about-history__content-item--' + $(this).data('tab'));

      $tab.css('display', 'flex').hide().fadeIn();
    }

  });

  if ($('.about-advantages__employees-img').length > 0) {

    let employeesPopper = new Popper(
      $('.about-advantages__employees-img'),
      document.querySelector('.about-advantages-employees-popup'), {
      placement: 'top',
      modifiers: {
        offset: {
          enabled: true,
          offset: '0,15'
        }
      }
    }
    );

    $('.about-advantages__employees-img').on('mouseenter', function () {

      employeesPopper.reference = $(this)[0];
      employeesPopper.update();

      let name = $(this).data().name,
        title = $(this).data().title,
        desc = $(this).data().desc;

      $('.about-advantages-employees-popup__name span').text(name);
      $('.about-advantages-employees-popup__name small').text(title);
      $('.about-advantages-employees-popup__desc').text(desc);

      $('.about-advantages-employees-popup').stop().fadeIn();

    });

    $('.about-advantages__employees-img').on('mouseleave', function () {
      $('.about-advantages-employees-popup').stop().fadeOut();
    });

  }

  //////////////////////////////////////////////////////////////////////////////
  // RESEARCH
  //////////////////////////////////////////////////////////////////////////////

  $('.btn--show-research-contact-box').on('click', function () {
    $(this).toggleClass('btn--active');
    $('.research-contact-box').stop().slideToggle();
  });

  $('.btn--show-research-regulations-box').on('click', function () {
    $(this).toggleClass('btn--active');
    $('.research-regulations-box').stop().slideToggle();
  });

  $('.article-sidebar__add-event').on('click', function () {
    $(this).closest('.article-sidebar__block').find('.events-list__item-add-box').stop().fadeToggle();
  });

  //////////////////////////////////////////////////////////////////////////////
  // SHOW BUTTONS
  //////////////////////////////////////////////////////////////////////////////

  if ($('.show-button').length > 0) {

    $('.show-button').each(function () {

      new ShowButton($(this));

    });

  }

  //////////////////////////////////////////////////////////////////////////////
  // GALLERY
  //////////////////////////////////////////////////////////////////////////////

  if ($('.gallery').length > 0) {

    const galeries = new Array($('.gallery').not('.gallery--featured').length);

    let galleryNumber = $(this).closest('.gallery').data('number'),
      currentGallery, picIndex;

    function changePhoto(direction) {

      if (direction === 'prev') {

        picIndex = (picIndex === 0) ? (currentGallery.length - 1) : --picIndex;

      } else {

        picIndex = (picIndex === currentGallery.length - 1) ? 0 : ++picIndex;

      }

      $('.gallery__popup-img').stop().fadeOut(200, () => {
        $('.gallery__popup-img').attr('src', currentGallery[picIndex]);
        $('.gallery__popup-img').fadeIn(200);
      });

    }

    $('.gallery').not('.gallery--featured').each(function (index) {

      galeries[index] = [];
      $(this).data('number', index);

      $(this).find('.gallery__item').each(function () {
        galeries[index].push($(this).data('source'));
      });

    });

    $('.gallery__item').on('click', function () {

      let imgSrc = $(this).data('source') || $(this).find('img').attr('srcset').split(' ')[0];

      galleryNumber = $(this).closest('.gallery').data('number'),
        currentGallery = galeries[galleryNumber],
        picIndex = $(this).index();

      if (imgSrc) {

        if ($(this).closest('.gallery').hasClass('gallery--featured')) {
          $('.gallery__popup').addClass('gallery__popup--featured');
        } else {
          $('.gallery__popup').removeClass('gallery__popup--featured');
        }

        $('.gallery__popup-img').attr('src', imgSrc);
        $('.gallery__popup').css('display', 'flex').hide().fadeIn();
      }

      $('.gallery__popup-btn--prev').off().on('click', () => {
        changePhoto('prev');
      });

      $('.gallery__popup-btn--next').off().on('click', () => {
        changePhoto();
      });

    });

    // CLOSE MOUSE
    $('.gallery__popup-close-btn').on('click', () => {
      $('.gallery__popup').fadeOut();
    });

    // KEYBOARD HANDLE
    $(document).on('keyup', function (evt) {

      // CLOSE KEYBOARD
      if (evt.keyCode == 27) {
        $('.gallery__popup').fadeOut();
      }

      if ($('.gallery__popup').not('.gallery__popup--featured').is(':visible') && evt.keyCode == 37) {
        changePhoto('prev');
      }

      if ($('.gallery__popup').not('.gallery__popup--featured').is(':visible') && evt.keyCode == 39) {
        changePhoto();
      }

    });

  }

  //////////////////////////////////////////////////////////////////////////////
  // DECODE EMAIL ADRESSES FROM BASE64
  //////////////////////////////////////////////////////////////////////////////

  if ($('.show-email-btn').length > 0) {

    $('.show-email-btn').on('click', function (e) {

      let $btn = $(this);

      if (!($btn.hasClass('show-email-btn--active'))) {

        e.preventDefault();

        let name = atob($btn.data('name'));
        let domain = atob($btn.data('domain'));

        $btn.text(`${name}@${domain}`);
        $btn.attr('href', `mailto:${name}@${domain}`);
        $btn.addClass('show-email-btn--active');

      }

    });

  }

});

// SHARE BTN

const $shareBtn = $('.article-sidebar__share-btn');
const $shareDialog = $('.article-sidebar__share-dialog');

if($shareBtn.length) {
  $shareBtn.on('click', function() {
    console.log($shareDialog);
    $shareDialog.toggleClass('article-sidebar__share-dialog--active');
  });
}

/* AUTOCOMPLETE */
// let AutoComplete = require("autocomplete-js");
// AutoComplete({
//   HttpHeaders: {
//     "X-Requested-With": "XMLHttpRequest"
//   }
// });
// import '../../../node_modules/jquery-ui/ui/widgets/autocomplete';
// import '../../../node_modules/jquery-ui/ui/widgets/menu';
// import '../../../node_modules/jquery-ui/ui/keycode';
// import '../../../node_modules/jquery-ui/ui/position';
// import '../../../node_modules/jquery-ui/ui/unique-id';
// import '../../../node_modules/jquery-ui/ui/widget';

// $(() => {
//   let cache = {};
//   $('#searchQuery, #modalQuery').autocomplete({
//     source: function (request, response) {
//       var term = request.term;
//       if (term in cache) {
//         response(cache[term]);
//         return;
//       }

//       $.ajax({
//         url: this.element.parents('form:first').attr('action'),
//         dataType: 'json',
//         data: {
//           query: request.term
//         },
//         success: function (data) {
//           cache[ term ] = data;
//           response(data);
//         }
//       });
//     },
//     minLength: 2,
//     select: function (event, ui) {
//       $(this).val(ui.item.value);
//       $(this).parents('form:first').trigger('submit');
//     }
//   });
// });
