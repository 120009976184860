import $ from 'jquery';
import './lib/jquery-plugin-query-object.js';
import Foundation from './lib/foundation-explicit-pieces';
import Popper from '../../../node_modules/popper.js/dist/popper';

$(() => {

//////////////////////////////////////////////////////////////////////////////
// CALENDAR
//////////////////////////////////////////////////////////////////////////////

  new Foundation.Dropdown($('#calendar-navigation-change-view'), {
    position: 'bottom',
    alignment: 'left',
    closeOnClick: true
  });

  if ($('.calendar-navigation__categories-dropdown').length > 0) {

    $('.calendar-navigation__categories-button').on('click', function () {
      $('.calendar-navigation__categories-dropdown-content').stop().slideToggle();
    });

    // CATEGORY CHECKBOXES /////////////////////////////////////////////////////

    let location = window.location.toString(),
        categories = location.includes('category=') ? $.query.get('category').toString().split(',') : [];

    // SELECT ACTIVE ON LOAD
    if( $('.calendar-navigation__categories-dropdown-item').length > 0 ) {

      $('.calendar-navigation__categories-dropdown-item input[type="checkbox"]').each(function() {

        let catID = $(this).data('category').toString();

        if (categories.indexOf( catID ) !== -1 ) {
          $(this).attr('checked', true);
        }

      });

    }

    // CHECK CLICK
    $('.calendar-navigation__categories-dropdown-item input[type="checkbox"]').on('click', function(){

      let catID = $(this).data('category').toString();

      if ($(this).attr('checked')) {

        for( let i = 0; i < categories.length; i++) {
          if ( categories[i] === catID) {
            categories.splice(i, 1);
            $(this).attr('checked', false);
          }
       }

      } else {
        categories.push(catID);
        $(this).attr('checked', true);
      }

    });

    $('.calendar-navigation__filter-btn').on('click', () => {

      if (categories.length > 0 ) {
        window.location.search = $.query.set('category', categories.toString());
      } else {
        window.location.search = $.query.REMOVE('category');
      }

    });

  }

  // CAMEL TO SNAKE FUNCTION
  function camelToSnakeCase(key) {
    return key.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return '-' + y.toLowerCase(); }).replace(/^_/, '');
  }

  // FOR WHOLE DAY
  function setDayData(data) {
    $('.calendar-modal__date-day').text(data['dateDay']);
    $('.calendar-modal__date-month').text(data['dateMonth']);
    $('.calendar-modal__date-year').text(data['dateYear']);

    if (data['link']) {
      $('.calendar-modal .btn').attr('href', data['link']);
      $('.calendar-modal .btn').show();
    } else {
      $('.calendar-modal .btn').hide();
    }

  }

  // FOR EACH EVENT IN DAY
  function setEventData(eventElement, data) {
    for (let key in data) {
      $(eventElement).find('.calendar-modal__' + camelToSnakeCase(key)).text(data[key]);
    }
  }

  // POPPERS CONFIG
  let currentEvent = null;

  if ($('.calendar').length) {
    const calendarPopper = new Popper(
      $('.calendar'),
      document.querySelector('.calendar-modal'), {
        placement: 'top'
      }
    );

    if (Foundation.MediaQuery.atLeast('xlarge')) {
      // desktop

      $('.calendar__event').on('click', function (e) {
        e.preventDefault();
        currentEvent = document.activeElement;

        if ($('.calendar-modal').is(':visible')) {

          $('body').removeClass('lock');
          $('.calendar-modal').removeClass('calendar-modal--long').fadeOut();

        } else {

          calendarPopper.reference = $(this)[0];
          calendarPopper.update();
          let eventData = $(this).data();

          setDayData(eventData);
          setEventData($('.calendar-modal__event'), eventData);

          $('.calendar-modal').stop().fadeIn();
          $('.calendar-modal__close-btn').trigger('focus');
        }

      });
    } else {
      // mobile

      $('.calendar__cell').on('click', function () {
        if (!($(this).find('.calendar__event').length > 0)) {
          return false;
        }

        let eventDataArr = [],
          eventsDF = $(document.createDocumentFragment());

        $($(this).find('.calendar__event')).each(function () {
          eventDataArr.push($(this).data());
        });

        for (let i = 0; i < eventDataArr.length; i++) {

          let $eventElement = $('.calendar-modal__event:last-child').clone();
          setDayData(eventDataArr[i]);
          setEventData($eventElement, eventDataArr[i]);
          eventsDF.append($eventElement);

        }

        $('.calendar-modal__content').html(eventsDF);

        if (eventDataArr.length > 3 && !Foundation.MediaQuery.atLeast('medium')) {
          $('body').toggleClass('lock');
          $('.calendar-modal').addClass('calendar-modal--long');
        }

        calendarPopper.reference = $(this)[0];
        calendarPopper.update();
        $('.calendar-modal').stop().css('display', 'flex').hide().fadeIn();
      });
    }

  }

  if ($('.calendar-modal__close-btn').length) {
    $('.calendar-modal__close-btn').on('click', function () {
      $('body').removeClass('lock');
      $('.calendar-modal').removeClass('calendar-modal--long').fadeOut();
      $(currentEvent).trigger('focus');
    });
  }

  // if ($('.events-list__item-calendar-btn').length) {
  //   $('.events-list__item-calendar-btn').on('click', function () {
  //     $(this).closest('.events-list__item').find('.events-list__item-add-box').fadeToggle();
  //   });
  // }

});
