import $ from 'jquery';

import { Foundation } from 'foundation-sites/js/foundation.core';
// eslint-disable-next-line
import * as CoreUtils from 'foundation-sites/js/foundation.core.utils';
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { Accordion } from 'foundation-sites/js/foundation.accordion';
import { Dropdown } from 'foundation-sites/js/foundation.dropdown';

Foundation.addToJquery($);

// Add Foundation Utils to Foundation global namespace for backwards
// compatibility.
Foundation.Keyboard = Keyboard;
Foundation.MediaQuery = MediaQuery;

MediaQuery._init();

Foundation.plugin(Accordion, 'Accordion');
Foundation.plugin(Dropdown, 'Dropdown');

export default Foundation;
