import $ from 'jquery';

class ShowButton {

  constructor(trigger) {
    this.$trigger = trigger;
    this.$target = $(this.$trigger).closest($(this.$trigger.data('wrapper'))).find($(this.$trigger.data('target')));
    this.hideWord = this.$trigger.data('hideWord');
    this.showWord = this.$trigger.text();
		this.$trigger.click( this.show.bind(this) );
  }

  show() {

    if (this.hideWord) {
      
      if (this.$trigger.hasClass('show-button--active')) {
        this.$trigger.text(this.showWord);
      } else {
        this.$trigger.text(this.hideWord);
      }

    }

    $(this.$trigger).toggleClass('show-button--active');
    $(this.$target).slideToggle();

  }
}

export { ShowButton as default };
